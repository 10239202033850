import React from 'react'

import { Event } from '@guiker/event'
import { Payout } from '@guiker/payment-shared'
import {
  ActivityChip,
  ActivityLabel,
  ActivityMarker,
  AnyColor,
  Source,
  useDateFormatter,
  useT,
} from '@guiker/react-framework'
import { money } from '@guiker/shared-framework'

const payoutEventStatusColorMapper = (status: string): AnyColor => {
  switch (status) {
    case 'FAILED':
      return 'alert'
    case 'PAID':
      return 'success'
    case 'CANCELLED':
      return 'info'
    case 'IN_TRANSIT':
      return 'active'
    case 'PAID_OFFLINE':
      return 'positive'
    default:
      return 'info'
  }
}

export const useTransformPayoutEvent = ({
  event,
  isPaidOffline,
}: {
  event: Event<Payout>
  isPaidOffline: boolean
}): { label: React.ReactNode; content: React.ReactNode } => {
  const { tMain } = useT({ domain: 'myInvestments', screenName: 'screens.payout.activityLog' })
  const { formatDateTime, formatDate } = useDateFormatter()
  const timestamp = formatDateTime(event.emittedAt)
  const payoutAmount = money.fromAmount(event.data.amount, event.data.currency).toString()
  const label = isPaidOffline ? 'PAID_OFFLINE' : event.type
  const statusColour = payoutEventStatusColorMapper(label)
  switch (event.type) {
    case 'PENDING':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain(`activity.status.${event.type}`, { amount: payoutAmount })} />,
      }
    case 'PAID':
      return {
        content: <Source content={timestamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.status.${label}`, { amount: payoutAmount })}
            chip={<ActivityChip content={tMain(`status.${label}`)} color={statusColour} />}
          />
        ),
      }
    case 'FAILED':
    case 'CANCELLED':
      return {
        content: <Source content={timestamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.status.${event.type}`, { amount: payoutAmount })}
            chip={<ActivityChip content={tMain(`status.${event.type}`)} color={statusColour} />}
          />
        ),
      }
    case 'IN_TRANSIT':
      return {
        content: <Source content={timestamp} />,
        label: (
          <ActivityLabel
            content={tMain(`activity.status.${!!event.data.expectedPayoutDate ? event.type : 'IN_TRANSIT_DATE'}`, {
              date: formatDate(event.data.expectedPayoutDate),
            })}
            chip={<ActivityChip content={tMain(`status.${event.type}`)} color={statusColour} />}
          />
        ),
      }
    default: {
      return
    }
  }
}

export const useTransformPayoutEventsToActivityItems = ({
  events,
  isPaidOffline,
}: {
  events: Event[]
  isPaidOffline: boolean
}) => {
  const filteredEvents = events
    ?.map((event) => useTransformPayoutEvent({ event: event as Event<Payout>, isPaidOffline }))
    ?.filter((item) => !!item.content)

  return filteredEvents?.map((item, index) => {
    return {
      ...item,
      marker: {
        isFirst: index === 0,
        isLast: index === filteredEvents.length - 1,
        icon: <ActivityMarker />,
      },
    }
  })
}
