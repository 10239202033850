import React from 'react'

import { money } from '@guiker/money'
import { Payout as PayoutComponent } from '@guiker/payout-app-components'
import {
  isPaidOffline,
  Payout as PayoutEntity,
  Transfer,
  TransferStatus,
  WithPayoutMethod,
  WithTransfers,
} from '@guiker/payout-shared'
import {
  ColumnConfig,
  Flex,
  H3,
  P,
  PaginatedTable,
  PaginatedTableProps,
  PSmall,
  PSmaller,
  useDateFormatter,
} from '@guiker/react-framework'

import { useT } from '../../i18n'

type Payout = WithTransfers<WithPayoutMethod<PayoutEntity>>

type PayoutTableProps = {
  fetcher: PaginatedTableProps<Payout>['fetcher']
}

const useGetColumns = (): ColumnConfig<Payout, Transfer>[] => {
  const { tMain } = useT({ screenName: 'components.listPayouts' })
  const { formatDate } = useDateFormatter()

  return [
    {
      name: 'payoutMethod',
      size: 3,
      label: tMain(`account`),
      renderValue: (payout) => {
        return (
          <PayoutComponent.PayoutMethodLabel payoutMethod={payout.payoutMethod} isPaidOffline={isPaidOffline(payout)} />
        )
      },
      collapsibleOptions: {
        verticalAlign: 'top',
      },
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: ({ info: { payerName, payerEmailAddress, description, label } }: Transfer) => {
        const secondaryInfo = { label, description }

        if (secondaryInfo.description.indexOf('/') !== -1) {
          const [firstLine, secondLine] = secondaryInfo.description.split('/')
          const [city] = secondLine?.split(',') || [secondLine]

          secondaryInfo.description = `${firstLine}, ${city}`
        }

        return [
          <Flex mb={1} flexDirection='column'>
            <PSmall>{payerName}</PSmall>
            <PSmaller>{payerEmailAddress}</PSmaller>
          </Flex>,
          <Flex flexDirection='column'>
            <PSmall>{secondaryInfo.description}</PSmall>
            <PSmaller>{secondaryInfo.label}</PSmaller>
          </Flex>,
        ]
      },
    },
    {
      name: 'status',
      size: 1,
      label: tMain(`status.label`),
      renderValue: (payout) => {
        return <PayoutComponent.PayoutStatusChip payout={payout} />
      },
      collapsibleOptions: {
        verticalAlign: 'top',
      },
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: (transfer: Transfer) => <PayoutComponent.TransferStatusChip transfer={transfer} />,
    },
    {
      name: 'createdAt',
      label: tMain('initiateDate'),
      size: 1,
      renderValue: (payout) => {
        return formatDate(payout.createdAt)
      },
      collapsibleOptions: {
        verticalAlign: 'top',
      },
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: ({ createdAt }: Transfer) => formatDate(createdAt),
    },
    {
      label: tMain(`amount`),
      headerOptions: {
        textAlign: 'right',
      },
      name: 'amount',
      size: 1,
      renderValue: (payout) => {
        return (
          <P mb={0} textAlign='right'>
            {money.fromAmount(payout.amount, payout.currency).toString(true)}
          </P>
        )
      },
      collapsibleOptions: {
        verticalAlign: 'top',
      },
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: (transfer) => {
        const isNegative = transfer.status === TransferStatus.REVERSED
        const amount = money
          .fromAmount(transfer.amount, transfer.parent?.currency)
          .toString({ currencySymbol: 'onlySymbol', isNegative })

        return (
          <PSmall mb={0} textAlign='right'>
            {amount}
          </PSmall>
        )
      },
    },
  ]
}

const PayoutTable: React.FC<PayoutTableProps> = ({ fetcher }) => {
  const { tMain } = useT({ screenName: 'components.listPayouts' })
  const columns = useGetColumns()

  return (
    <Flex flexDirection='column'>
      <H3>{tMain('title')}</H3>
      <PaginatedTable
        fetcher={fetcher}
        queryKey='readAllPayouts'
        columns={columns}
        emptyState={{
          label: tMain('emptyState.label'),
          description: tMain('emptyState.description'),
        }}
        isRowCollapsible={true}
        collapsibleOptions={{
          isOpen: false,
        }}
      />
    </Flex>
  )
}

export { PayoutTable }
